import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ChevronLeft, ChevronRight, Play, Square, Download, Copy, Clipboard, Circle, Trash2, ArrowLeft, ArrowRight, ArrowUp, ArrowDown, Eye, EyeOff } from 'lucide-react';

const colors = ['blue', 'red', 'green', 'cyan', 'magenta', 'yellow', 'white'];
// const noteColors = ['blue', 'red', 'green'];

const Triangle = ({ size = 24, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M3 20h18L12 4z" />
    </svg>
);

const shapeLibrary = {
    circle: [
        [0, 1, 1, 0],
        [1, 0, 0, 1],
        [1, 0, 0, 1],
        [0, 1, 1, 0]
    ],
    square: [
        [1, 1, 1, 1],
        [1, 0, 0, 1],
        [1, 0, 0, 1],
        [1, 1, 1, 1]
    ],
    triangle: [
        [0, 0, 1, 0],
        [0, 1, 0, 1],
        [1, 0, 0, 1],
        [1, 1, 1, 1]
    ]
};

const MidiSequencer = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedColor, setSelectedColor] = useState('blue');
    const [sequence, setSequence] = useState(Array(16).fill().map(() => Array(64).fill(null)));
    const [midiOutput, setMidiOutput] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [copiedStep, setCopiedStep] = useState(null);
    const [tempo, setTempo] = useState(120);
    const [selectedShape, setSelectedShape] = useState(null);
    const [showPreviousStep, setShowPreviousStep] = useState(false);
    const [showMidiNotes, setShowMidiNotes] = useState(false);
    const [hoverPosition, setHoverPosition] = useState(null);
    const gridRef = useRef(null);
    const drawingModeRef = useRef(null);

    useEffect(() => {
        if (navigator.requestMIDIAccess) {
            navigator.requestMIDIAccess().then(onMIDISuccess, onMIDIFailure);
        } else {
            console.log('Web MIDI API is not supported in this browser.');
        }
    }, []);

    const onMIDISuccess = (midiAccess) => {
        const outputs = midiAccess.outputs.values();
        setMidiOutput(outputs.next().value);
    };

    const onMIDIFailure = () => {
        console.log('Could not access your MIDI devices.');
    };

    const sendMIDIMessage = useCallback((note, velocity) => {
        if (midiOutput) {
            const noteOnMessage = [0x90, note, velocity];
            const noteOffMessage = [0x80, note, 0];
            midiOutput.send(noteOnMessage);
            setTimeout(() => midiOutput.send(noteOffMessage), 100);
        }
    }, [midiOutput]);

    const mapIndexToMIDINotes = (index) => {
        const totalColumns = 8;
        const totalRows = 8;
        const colorCount = 3;
        const maxMidiNote = 127;

        // Calculate column and row
        const column = index % totalColumns;
        const row = Math.floor(index / totalRows);

        let blue, red, green;

        let i = (column * totalColumns * colorCount) + row
        blue = maxMidiNote - i
        red = blue - totalRows
        green = red - totalRows

        return { blue, red, green };
    };

    const handleSquareInteraction = (stepIndex, squareIndex) => {
        setSequence(prevSequence => {
            const newSequence = [...prevSequence];
            newSequence[stepIndex] = [...newSequence[stepIndex]];

            if (selectedShape) {
                const shape = shapeLibrary[selectedShape];
                const startRow = Math.floor(squareIndex / 8);
                const startCol = squareIndex % 8;

                for (let i = 0; i < shape.length; i++) {
                    for (let j = 0; j < shape[i].length; j++) {
                        const row = startRow + i;
                        const col = startCol + j;
                        if (row < 8 && col < 8) {
                            const index = row * 8 + col;
                            if (shape[i][j]) {
                                if (drawingModeRef.current === null) {
                                    drawingModeRef.current = newSequence[stepIndex][index] !== selectedColor;
                                }
                                newSequence[stepIndex][index] = drawingModeRef.current ? selectedColor : null;
                            }
                        }
                    }
                }
            } else {
                if (drawingModeRef.current === null) {
                    drawingModeRef.current = newSequence[stepIndex][squareIndex] !== selectedColor;
                }
                newSequence[stepIndex][squareIndex] = drawingModeRef.current ? selectedColor : null;
            }

            return newSequence;
        });
    };

    const handleMouseDown = (e) => {
        setIsDrawing(true);
        drawingModeRef.current = null;
        const { stepIndex, squareIndex } = getIndicesFromEvent(e);
        if (stepIndex !== -1 && squareIndex !== -1) {
            handleSquareInteraction(stepIndex, squareIndex);
        }
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        drawingModeRef.current = null;
    };

    const handleMouseMove = (e) => {
        const { stepIndex, squareIndex } = getIndicesFromEvent(e);
        setHoverPosition(stepIndex !== -1 && squareIndex !== -1 ? { stepIndex, squareIndex } : null);

        if (isDrawing) {
            if (stepIndex !== -1 && squareIndex !== -1) {
                handleSquareInteraction(stepIndex, squareIndex);
            }
        }
    };

    const getIndicesFromEvent = (e) => {
        if (!gridRef.current) return { stepIndex: -1, squareIndex: -1 };

        const rect = gridRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const squareSize = rect.width / 8;

        const stepIndex = currentStep;
        const squareIndex = Math.floor(y / squareSize) * 8 + Math.floor(x / squareSize);

        if (squareIndex >= 0 && squareIndex < 64) {
            return { stepIndex, squareIndex };
        }
        return { stepIndex: -1, squareIndex: -1 };
    };

    const handleStepChange = (direction) => {
        setCurrentStep((prevStep) => (prevStep + direction + 16) % 16);
    };

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const handleCopyStep = () => {
        setCopiedStep([...sequence[currentStep]]);
    };

    const handlePasteStep = () => {
        if (copiedStep) {
            setSequence(prevSequence => {
                const newSequence = [...prevSequence];
                newSequence[currentStep] = [...copiedStep];
                return newSequence;
            });
        }
    };

    const handleTempoChange = (e) => {
        setTempo(Number(e.target.value));
    };

    const handleClearStep = () => {
        setSequence(prevSequence => {
            const newSequence = [...prevSequence];
            newSequence[currentStep] = Array(64).fill(null);
            return newSequence;
        });
    };

    const shiftStep = (direction) => {
        setSequence(prevSequence => {
            const newSequence = [...prevSequence];
            const currentStepData = [...newSequence[currentStep]];
            const shiftedData = new Array(64).fill(null);

            for (let i = 0; i < 8; i++) {
                for (let j = 0; j < 8; j++) {
                    let newI, newJ;
                    switch (direction) {
                        case 'left':
                            newI = i;
                            newJ = (j - 1 + 8) % 8;
                            break;
                        case 'right':
                            newI = i;
                            newJ = (j + 1) % 8;
                            break;
                        case 'up':
                            newI = (i - 1 + 8) % 8;
                            newJ = j;
                            break;
                        case 'down':
                            newI = (i + 1) % 8;
                            newJ = j;
                            break;
                        default:
                            newI = i;
                            newJ = j;
                    }
                    shiftedData[newI * 8 + newJ] = currentStepData[i * 8 + j];
                }
            }

            newSequence[currentStep] = shiftedData;
            return newSequence;
        });
    };

    useEffect(() => {
        if (isPlaying) {
            const interval = setInterval(() => {
                setCurrentStep((prevStep) => (prevStep + 1) % 16);
                sequence[currentStep].forEach((color, index) => {
                    if (color) {
                        const midiNotes = mapIndexToMIDINotes(index);
                        const note = midiNotes[color];
                        if (note !== null && note >= 0 && note <= 127) {
                            sendMIDIMessage(note, 127);
                        }
                    }
                });
            }, 60000 / (tempo * 4)); // 16th notes
            return () => clearInterval(interval);
        }
    }, [isPlaying, currentStep, sequence, sendMIDIMessage, tempo]);

    const downloadMIDI = () => {
        console.log('MIDI download not implemented');
    };

    const toggleShowPreviousStep = () => {
        setShowPreviousStep(prev => !prev);
    };

    const toggleShowMidiNotes = () => {
        setShowMidiNotes(prev => !prev);
    };

    const getPreviousStep = () => {
        return (currentStep - 1 + 16) % 16;
    };

    const renderHoverPreview = () => {
        if (!selectedShape || !hoverPosition) return null;

        const shape = shapeLibrary[selectedShape];
        const startRow = Math.floor(hoverPosition.squareIndex / 8);
        const startCol = hoverPosition.squareIndex % 8;

        return shape.map((row, i) =>
            row.map((cell, j) => {
                const gridRow = startRow + i;
                const gridCol = startCol + j;
                if (gridRow < 8 && gridCol < 8 && cell) {
                    const index = gridRow * 8 + gridCol;
                    return (
                        <div
                            key={`preview-${index}`}
                            className="absolute w-12 h-12"
                            style={{
                                top: `${gridRow * 3}rem`,
                                left: `${gridCol * 3}rem`,
                                backgroundColor: selectedColor,
                                opacity: 0.3,
                            }}
                        />
                    );
                }
                return null;
            })
        );
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
            <div className="mb-4 flex items-center">
                <button onClick={() => handleStepChange(-1)} className="mr-2"><ChevronLeft /></button>
                <span className="mx-2">Step {currentStep + 1}</span>
                <button onClick={() => handleStepChange(1)} className="ml-2"><ChevronRight /></button>
                <button onClick={handleCopyStep} className="ml-4 p-2 bg-blue-500 rounded" title="Copy current step">
                    <Copy size={16} />
                </button>
                <button
                    onClick={handlePasteStep}
                    className={`ml-2 p-2 rounded ${copiedStep ? 'bg-green-500' : 'bg-gray-500'}`}
                    disabled={!copiedStep}
                    title="Paste to current step"
                >
                    <Clipboard size={16} />
                </button>
                <button
                    onClick={handleClearStep}
                    className="ml-2 p-2 bg-red-500 rounded"
                    title="Clear current step"
                >
                    <Trash2 size={16} />
                </button>
                <button
                    onClick={toggleShowPreviousStep}
                    className={`ml-2 p-2 rounded ${showPreviousStep ? 'bg-blue-500' : 'bg-gray-500'}`}
                    title={showPreviousStep ? "Hide previous step" : "Show previous step"}
                >
                    {showPreviousStep ? <EyeOff size={16} /> : <Eye size={16} />}
                </button>
                <button
                    onClick={toggleShowMidiNotes}
                    className={`ml-2 p-2 rounded ${showMidiNotes ? 'bg-blue-500' : 'bg-gray-500'}`}
                    title={showMidiNotes ? "Hide MIDI notes" : "Show MIDI notes"}
                >
                    {showMidiNotes ? 'Hide Notes' : 'Show Notes'}
                </button>
            </div>
            <div
                ref={gridRef}
                className="grid grid-cols-8 gap-px bg-gray-700 p-px relative"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseUp}
            >
                {sequence[currentStep].map((color, index) => {
                    const midiNotes = mapIndexToMIDINotes(index);
                    return (
                        <div
                            key={index}
                            className="w-12 h-12 cursor-pointer relative"
                            style={{ backgroundColor: color || 'black' }}
                        >
                            {showPreviousStep && sequence[getPreviousStep()][index] && (
                                <div
                                    className="absolute inset-0 opacity-30"
                                    style={{ backgroundColor: 'darkgray' }}
                                />
                            )}
                            {showMidiNotes && (
                                <>
                                    <div className="absolute top-0 left-0 text-xs text-white">
                                        {index}
                                    </div>
                                    {midiNotes.blue !== null && (
                                        <div className="absolute top-2 left-0 text-xs text-blue-500">
                                            {midiNotes.blue}
                                        </div>
                                    )}
                                    {midiNotes.green !== null && (
                                        <div className="absolute bottom-0 left-0 text-xs text-green-500">
                                            {midiNotes.green}
                                        </div>
                                    )}
                                    {midiNotes.red !== null && (
                                        <div className="absolute top-2 right-0 text-xs text-red-500">
                                            {midiNotes.red}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
                <div className="absolute inset-0">
                    {renderHoverPreview()}
                </div>
            </div>
            <div className="mt-4 flex space-x-2">
                {colors.map((color) => (
                    <button
                        key={color}
                        className={`w-8 h-8 rounded-full ${selectedColor === color ? 'ring-2 ring-white' : ''}`}
                        style={{ backgroundColor: color }}
                        onClick={() => setSelectedColor(color)}
                    />
                ))}
            </div>
            <div className="mt-4 flex space-x-4 items-center">
                <button onClick={togglePlay}>
                    {isPlaying ? <Square /> : <Play />}
                </button>
                <button onClick={downloadMIDI}>
                    <Download />
                </button>
                <div className="flex items-center">
                    <label htmlFor="tempo" className="mr-2">Tempo:</label>
                    <input
                        type="range"
                        id="tempo"
                        min="60"
                        max="240"
                        value={tempo}
                        onChange={handleTempoChange}
                        className="w-32"
                    />
                    <span className="ml-2">{tempo} BPM</span>
                </div>
            </div>
            <div className="mt-4 flex space-x-4">
                <button
                    onClick={() => setSelectedShape(selectedShape === 'circle' ? null : 'circle')}
                    className={`p-2 rounded ${selectedShape === 'circle' ? 'bg-blue-500' : 'bg-gray-500'}`}
                >
                    <Circle size={24} />
                </button>
                <button
                    onClick={() => setSelectedShape(selectedShape === 'triangle' ? null : 'triangle')}
                    className={`p-2 rounded ${selectedShape === 'triangle' ? 'bg-blue-500' : 'bg-gray-500'}`}
                >
                    <Triangle size={24} />
                </button>
                <button
                    onClick={() => setSelectedShape(selectedShape === 'square' ? null : 'square')}
                    className={`p-2 rounded ${selectedShape === 'square' ? 'bg-blue-500' : 'bg-gray-500'}`}
                >
                    <Square size={24} />
                </button>
            </div>
            <div className="mt-4 grid grid-cols-3 gap-2">
                <div></div>
                <button onClick={() => shiftStep('up')} className="p-2 bg-gray-500 rounded">
                    <ArrowUp size={24} />
                </button>
                <div></div>
                <button onClick={() => shiftStep('left')} className="p-2 bg-gray-500 rounded">
                    <ArrowLeft size={24} />
                </button>
                <button onClick={() => shiftStep('down')} className="p-2 bg-gray-500 rounded">
                    <ArrowDown size={24} />
                </button>
                <button onClick={() => shiftStep('right')} className="p-2 bg-gray-500 rounded">
                    <ArrowRight size={24} />
                </button>
            </div>
        </div>
    );
};

export default MidiSequencer;
