import React from 'react';
import MidiSequencer from './MidiSequencer';

function App() {
  return (
    <div className="App">
      <MidiSequencer />
    </div>
  );
}

export default App;
